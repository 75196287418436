<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('deal.deal_wizard.title') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="list"></octo-icon>
            <p>{{ $t('deal.deal_wizard.registry_choice') }}</p>
          </template>
          <deal-wizard-first-step ref="dealWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="academic"/>
            <p>{{ $t('deal.deal_wizard.course_choice') }}</p>
          </template>
          <deal-wizard-second-step ref="dealWizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="annual-calendar-page"/>
            <p>{{ $t('deal.deal_wizard.appointment_details') }}</p>
          </template>
          <deal-wizard-third-step ref="dealWizardThirdStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(4)">
          <template slot="label">
            <octo-icon icon="statistics"/>
            <p>{{ $t('deal.deal_wizard.report') }}</p>
          </template>
          <deal-wizard-fourth-step :appointment="appointment"
                                   :registry="registry"
                                   :operator-id="operatorId"
                                   :sales-id="saleId"
                                   :course-ids="courseIds"
                                   :text="text"
                                   :key="reportKey"/>
        </wizard-tab>

      </simple-wizard>
    </div>
  </div>
</template>

<script>
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Registry from "@/models/registry";
import DealWizardFirstStep from "@/pages/Deals/deal-wizard/DealWizardFirstStep";
import DealWizardSecondStep from "@/pages/Deals/deal-wizard/DealWizardSecondStep";
import DealWizardThirdStep from "@/pages/Deals/deal-wizard/DealWizardThirdStep";
import DealWizardFourthStep from "@/pages/Deals/deal-wizard/DealWizardFourthStep";
import {endpoints} from "@/routes/endpoints";
import {MessageBox} from "element-ui";

export default {
  name: "WizardPage",
  components: {
    DealWizardFourthStep,
    DealWizardThirdStep,
    DealWizardSecondStep,
    DealWizardFirstStep,
    OctoIcon,
    WizardTab,
    SimpleWizard,
  },
  data() {
    return {
      registry: this.$_.cloneDeep(Registry),
      saleId: null,
      operatorId: null,
      appointment: {start:null, end: null},
      text: '',
      courseIds: [],
      reportKey: 0
    }
  },
  methods: {
    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const registry = this.$refs.dealWizardFirstStep.getSelectedRegistry();
          if (registry) {
            this.registry = registry;
            check = true;
            this.reportKey++
          }
          break;
        case 2:
          const courseIds = await this.$refs.dealWizardSecondStep.getCourseIds();
          if (courseIds) {
            this.courseIds = courseIds;
            this.text = this.$refs.dealWizardSecondStep.getNotes();
            check = true;
          }
          break;
        case 3:
          const data = await this.$refs.dealWizardThirdStep.getAppointmentDetails();
          if (data) {
            this.saleId = data.saleId;
            this.operatorId = data.operatorId;
            this.appointment = data.appointment;
            check = true;
          }
          break;
        case 4:
          check = true;
          break;
        default:
          check = false;
          break;
      }
      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    completeWizard() {
      this.$api.post(
        endpoints.DEAL_FROM_REGISTRY
        .replace('{registryId}', this.registry.id),
        {
          courseIds: this.courseIds,
          saleId: this.saleId,
          operatorId: this.operatorId,
          text: this.text,
          appointment: this.appointment
        }
      )
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('deal.deal_wizard.back_on_deals_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'deals.archive'})
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })

    }
  }
}
</script>

<style scoped>

</style>
