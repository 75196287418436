<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('deal.deal_wizard.fourth_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <registry-personal-data :editable="false" :registry="registry" :key="registryKey"/>

        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.courses') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component v-for="(courseId, index) in courseIds"
                                       v-bind:key="index"
                                       :label="courses[courseId] | optional('title')"
            />
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-4">
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.operator') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.operator')">
              <template slot="value">
                <label-theme-component>
                  {{ users[operatorId] | optional('firstname') }}
                  {{ users[operatorId] | optional('lastname') }}
                </label-theme-component>
              </template>
            </list-group-item-component>
          </ul>
        </card>

        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('deal.deal_wizard.appointment') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('page.sales_singular')">
              <template slot="value">
                <label-theme-component>
                  {{ users[salesId] | optional('firstname') }}
                  {{ users[salesId] | optional('lastname') }}
                </label-theme-component>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.date_start')" :value="appointment.start | datetime"/>
            <list-group-item-component :label="$t('fields.date_end')" :value="appointment.end | datetime"/>
          </ul>
        </card>
        <card class="shadow" body-classes="standard-card-body" v-if="text">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.notes') }}</h4>
          </div>
          <p>{{ text }}</p>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Registry from "@/models/registry";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealWizardFourthStep",
  components: {LabelThemeComponent, ListGroupItemComponent, RegistryPersonalData},
  data() {
    return {
      registryKey: 0
    }
  },
  mounted() {
    this.registryKey++;
  },
  props: {
    salesId: {
      default: null,
    },
    operatorId: {
      default: null,
    },
    courseIds: {
      type: Array,
      default: () => [],
    },
    registry: {
      type: Object,
      default: Registry
    },
    appointment: {
      type: Object,
      default: () => ({start:null, end: null})
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      courses: 'common/allCourses',
    }),
  },
}
</script>

<style scoped>

</style>
