<template>
  <ValidationObserver ref="thirdStep">
    <div class="container-fluid">
      <div class="row justify-content-center mb-3">
        <div class="col-auto text-center">
          <p>{{ $t('deal.deal_wizard.third_step_title') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <ValidationProvider name="operator" rules="required" v-slot="{ passed, failed, errors }">
            <base-select
              :label="$t('fields.operator')"
              :options="operatorOptions"
              v-model="operatorId"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            />
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="sale" rules="required" v-slot="{ passed, failed, errors }">
            <base-select
              :label="$t('fields.sale')"
              :options="salesOptions"
              v-model="saleId"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            />
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="date" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.date')"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-date-picker
                v-model="dateStart"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('fields.date')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="hour" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.hour')"
                        class="flex-grow-1"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-time-select
                v-model="time"
                format="HH:mm"
                value-format="HH:mm"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>

import {mapGetters} from "vuex";
import {DatePicker, Select, Option, TimeSelect} from "element-ui";
import BaseSelect from "@/components/Inputs/BaseSelect";

export default {
  name: "DealWizardThirdStep",
  components: {
    BaseSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      saleId: null,
      operatorId: null,
      time: null,
      dateStart: null
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      sales: 'common/sales',
      operators: 'common/operators',
      settings: 'common/settings',
    }),

    salesOptions() {
      return this.$_.map(this.sales, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },

    operatorOptions() {
      return this.$_.map(this.operators, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },

    pickerOptions() {
      return {
        start: this.settings?.callcenter_calendar_start + ':00',
        end: this.settings?.callcenter_calendar_end + ':00',
        step: '00:30',
      }
    }
  },
  methods: {
    getAppointmentDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.thirdStep.validate()
          .then((success) => {
            if (success) {
              const dateTimeStart = this.$moment(this.dateStart + ' ' + this.time);

              resolve({
                saleId: this.saleId,
                operatorId: this.operatorId,
                appointment: {
                  start: dateTimeStart.format('YYYY-MM-DD HH:mm:ss'),
                  end: dateTimeStart
                    .add(this.settings?.callcenter_appointment_duration || 90, 'minutes')
                    .format('YYYY-MM-DD HH:mm:ss')
                }
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
