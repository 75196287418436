<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('deal.deal_wizard.first_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <octo-registry-selector ref="octoRegistrySelector"/>
      </div>
    </div>
  </div>
</template>

<script>
import OctoRegistrySelector from "@/components/octo-registry-selector/OctoRegistrySelector";

export default {
  name: "DealWizardFirstStep",
  components: {OctoRegistrySelector},
  methods: {
    getSelectedRegistry() {
      return this.$refs.octoRegistrySelector.getSelectedRegistry();
    }
  }
}
</script>

<style scoped>

</style>
