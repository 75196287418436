<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('deal.deal_wizard.second_step_title') }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <course-selector ref="courseSelector"/>
      </div>
      <div class="col-12">
        <base-text-area :label="$t('fields.notes')" v-model="text" :rows="8"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import CourseSelector from "../../Courses/components/CourseSelector";

export default {
  name: "DealWizardSecondStep",
  components: {CourseSelector, BaseTextArea},
  data() {
    return {
      text: ''
    }
  },
  methods: {
    getCourseIds() {
      return new Promise((resolve, reject) => {
          const courseIds = this.$_.map(this.$refs.courseSelector.getSelectedCourses(), course => {
            return course.id;
          });

          if (courseIds.length > 0) {
            resolve(courseIds);
          }

          reject();
        }
      )
    },

    getNotes() {
      return this.text;
    }
  }
}
</script>

<style scoped>

</style>
