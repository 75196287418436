<template>
  <div
    class="form-group"
    :class="{
      'has-label': label,
    }"
  >
    <slot name="label">
      <label v-if="label" class="text-capitalize"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0">
      <slot>
        <el-select
          filterable
          :class="`select-${textType} text-uppercase`"
          :value="localValue"
          @change="onChange"
        >
          <el-option
            v-for="option in options"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
      </slot>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {Option, Select} from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  inheritAttrs: false,
  name: 'base-select',
  props: {
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      localValue : null,
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    textType: function () {
      if(this.type) {
        return this.type;
      }
      switch(this.themeColor) {
        case 'primary':
          return 'primary';
        case 'vue':
        case 'green':
          return 'success';
        case 'red':
          return 'danger';
        case 'orange':
          return 'warning';
        case 'blue':
          return 'info';
        default:
          return 'primary';
      }
    }
  },
  watch: {
    value(val, oldVal) {
      this.localValue = val;
    }
  },
  methods: {
    onChange(value) {
      this.localValue = value;
      this.$emit('input', value);
    },
  }
};
</script>
<style></style>
